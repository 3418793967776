<template>
  <v-container>
    <div
      v-for="(sequence, i) in sequences"
      :key="i"
    >
      <transition name="fade" >
        <v-row v-if="sequence.step==step & sequence.type=='text'">
          <v-col>
            <v-card
              class="mx-auto"
            >
              <v-img
                class="align-end text-white"
                :src=getImageFile(sequence.image_file)
                cover
              />
              <v-card-title v-html="sequence.title">
              </v-card-title>
              <v-card-text v-html="sequence.text.replace(/\n/g,'<br>')">
              </v-card-text>

              <div v-if="(sequence.sound_file !== undefined) && (sequence.sound_file != '')">
                {{$emit('playSound', sequence.sound_file)}}
              </div>
            </v-card>
          </v-col>
        </v-row>
      </transition>

      <transition name="fade">
        <v-row v-if="sequence.step==step & sequence.type=='button'">
          <v-col>
            <v-btn 
              block
              v-on:click="$emit('setStep', sequence.next_step)"
              color='black'
            ><v-icon>mdi-arrow-right-thin-circle-outline</v-icon>{{sequence.text}}</v-btn>
          </v-col>
        </v-row>
      </transition>

      <transition name="fade">
        <v-row v-if="sequence.step==step & sequence.type=='form'">
          <v-col>
            <v-card
              class="mx-auto"
            >
              <v-card-title v-html="sequence.title">
              </v-card-title>
              <v-form
                ref="form"
                class="px-4 pb-4"
                lazy-validation
                @submit.prevent="submitForm(sequence.answer, sequence.next_step)"
              >
                <v-card-text v-html="sequence.text.replace(/\n/g,'<br>')">
                </v-card-text>
                <v-text-field
                  v-model="form.answer"
                  :counter="10"
                  label="回答"
                  clearable 
                  :rules="[required]"
                ></v-text-field>
                <v-btn
                  :disabled="!form"
                  block
                  color="success"
                  size="large"
                  type="submit"
                  variant="elevated"
                  >
                    送信
                </v-btn>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </transition>

      <div v-if="sequence.step==step & sequence.type=='wait'">
        {{$emit('setWait',sequence.time, sequence.next_step)}}
      </div>

    </div>
  </v-container>
  
  <v-snackbar v-model="snackbarVisible" :timeout="3000" multi-line>
    {{ snackbarMessage }}
    <v-btn color="white" text @click="snackbarVisible = false">Close</v-btn>
  </v-snackbar>
</template>

<style>
/* 表示時の状態 */
.fade-enter-from {
  opacity: 0;
}
/* 表示時のアクティブ状態 */
.fade-enter-active {
  transition: all 1s;
}
/* 表示時の終了状態 */
.fade-enter-to {
  opacity: 1;
}
/* 非表示時の状態 */
.fade-leave-from {
  opacity: 1;
}
/* 非表示時のアクティブ状態 */
.fade-leave-active {
  transition: all 0s;
  transition-delay: 0s;
}
/* 非表示時の終了状態 */
.fade-leave-to {
  opacity: 0;
}
</style>

<script>
export default {
  name: 'ScenarioX',
  
  props: ['step', 'sequences'],
  emits: ['setWait', 'setStep', 'playSound'],

  data: () => ({
    form: {
      answer: '',
    },
    snackbarVisible: false,
    snackbarMessage: ''
  }),
  
  methods: {
    getImageFile(image_file){
      if(image_file=== undefined || image_file==''){
        return '';
      }else if(image_file.startsWith('https:')){
        return image_file;
      }else{
        return require('../assets/' + image_file);
      }
    },

    submitForm(correct, next_step){
      const regex = eval(correct);
      if(regex.test(this.form.answer)){
        this.$emit('setStep', next_step);
        this.snackbarVisible=true;
        this.snackbarMessage = '正解です！';
        this.form.answer = '';
      }else{
        this.snackbarVisible=true;
        this.snackbarMessage = '回答が違います。';
      }
    },

    required (v) {
        return !!v || '回答を入力してください'
    },

    dynamicStyles(i){
      return {
        transitionDelay: i+"s",
      };
    },
  },

  computed:{

  },

  mounted(){

  },
}

</script>
