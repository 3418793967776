<template>
  <v-app>
    <MainHeader />
    <v-main>
      <ScenarioX
        :step="step"
        :sequences="sequences"
        @setWait="setWait"
        @setStep="setStep"
        @playSound="playSound"
      />
    </v-main>

    <v-footer class="bg-grey-lighten-1 pa-5">
      <v-row>
        <v-btn 
            block
            variant="outlined"
            size="small"
            v-on:click="dialog=true"
            color="black"
        ><v-icon>mdi-lock-reset</v-icon>ゲーム進行のリセット</v-btn>

        <v-dialog
          v-model="dialog"
        >
        <div class="centered-element">
          <v-card >
            <v-card-text>
              ゲームの進行をリセットして、はじめから遊びますか？
            </v-card-text>
            <v-card-actions class="centered-element">
              <v-btn color="primary" @click="resetStep();dialog = false">はい</v-btn>
              <v-btn color="primary" @click="dialog = false">いいえ</v-btn>
            </v-card-actions>
          </v-card>
        </div>
        </v-dialog>

        <v-col class="text-center">
          <p class="pt-0 text-caption">2023 反社会人サークル</p>
          <div v-if="isDevelopment()">
            <p>debug</p>
            <p>再生ステータス：{{is_playing}}</p>
            <p>再生ファイル：{{playing_sound_file}}</p>
            <p>ウエイトステータス：{{is_waiting}}</p>
          </div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<style>
.centered-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>

<script>
import MainHeader from './components/MainHeader.vue'
import ScenarioX from './components/ScenarioX.vue'
import { Howl } from 'howler'

export default {
  name: 'App',

  components: {
    MainHeader,ScenarioX,
  },

  data: () => ({
    step : 1,
    sequences : [],
    sound: null,
    playing_sound_file: null,
    is_playing: false,
    is_waiting: false,
    dialog: false,
  }),

  methods: {
    isDevelopment(){
      return process.env.NODE_ENV==='development';
    },

    resetStep(){
      this.setStep(1);
      this.stopSound();
    },

    setStep(s){
      this.step = s;
      localStorage.step = this.step;
    },

    playSound(sound_file){
      if(sound_file==this.playing_sound_file) return '再生中';

      if(this.is_playing == true){
        this.sound.stop();
      }

      this.playing_sound_file = sound_file;
      this.sound = new Howl({
        src: require('./assets/' + sound_file),
        onload: () => {
          console.log('load sound:' + sound_file);
          this.sound.play();
        },
        onplay: () => {
          console.log('start sound:' + sound_file);
          this.is_playing = true;
        },
      });

      return '音声ロード中（音声が再生されない場合はタップ/クリック）';
    },

    stopSound(){
      if(this.sound == null)return;

      if(this.sound.playing()){
        this.sound.stop();
      }

      this.playing_sound_file = null;
      this.is_playing = false;
    },

    setWait(time, next_step){
      if(this.is_waiting==true)return;
      setTimeout(() => {
          this.setStep(next_step);
          this.is_waiting = false;
        }
        ,time*1000
      );
      this.is_waiting = true;
    },
  },

  created() {
    if(localStorage.step){
      this.step = localStorage.step;
      console.log("UPDATE step:"+this.step);
    }
  },
  
  mounted() {
    fetch('https://sheets.googleapis.com/v4/spreadsheets/1-VRrURSuorwqM-46Ac34oPP8SqNjcXUWeEfTl3mEs64/values/scenario?key=AIzaSyC5hzXBPv-t-6gaB3ALDHuGH0SAYrfWWIk')
      .then(res => res.json())
      .then(res => {
        const columns = res.values[0];
        const values = res.values.slice(1);
        for (const v in values){
          let sequence = {};
          for(const c in columns){
            sequence[columns[c]] = values[v][c];
          }

          this.sequences.push(sequence);
          
        }
        //console.log(this.sequences);
      })
      .catch(
        error => {
          console.log(error)
        }
    );
  },
}
</script>
