<template>
    <header class="black">
      <v-app-bar color="black" flat elevate-on-scroll>
        <v-toolbar-title ><h1><v-img src="/img/title_simple black.png" alt="ASMR刑事" max-height="40" /></h1></v-toolbar-title>
      </v-app-bar>
    </header>


</template>

<script>

export default {
  name: 'MainHeader',

  methods: {
  },

  data: () => ({
    
  }),
}

</script>
