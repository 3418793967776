import { createApp} from 'vue'
import App from './App.vue'
//import Index from './Index.vue'
import vuetify from './plugins/vuetify'
//import router from './router';
import { loadFonts } from './plugins/webfontloader'
//import {createRouter, createWebHashHistory} from 'vue-router'  
import { createGtm } from "vue-gtm";

loadFonts()

let app;
app = createApp(App);
/*if(location.pathname=="/"){
  app = createApp(App);
}else{
  app = createApp(Index);
}*/

app
  .use(vuetify)
  .use(createGtm({ id: "GTM-KF68X95",}))
  .mount('#app');
